<template>
  <div>
    <NuxtImg
      v-if="hasError === false || useFallback === false"
      v-bind="$attrs"
      :modifiers="{ breakpoint }"
      provider="strapiHaelsi"
      :src="data.attributes.url"
      :alt="data.attributes.alternativeText"
      :loading="lazy ? 'lazy' : 'eager'"
      :width="data.attributes.width"
      :height="data.attributes.height"
      @error="handleError" />
    <img
      v-else
      v-bind="$attrs"
      :src="alternativeUrl"
      :alt="data.attributes.alternativeText"
      :width="data.attributes.width"
      loading="lazy"
      :height="data.attributes.height" />
  </div>
</template>

<script setup lang="ts">
import { withoutBase } from "ufo";
import type { Media } from "~/types/strapi/common/schemas-to-ts/Media";
import { determineImageBreakpoint } from "~/util/determineImageBreakpoint";

defineOptions({
  inheritAttrs: false,
});

const config = useRuntimeConfig();
const props = withDefaults(
  defineProps<{
    data: Media;
    lazy?: boolean;
    useFallback?: boolean;
  }>(),
  {
    lazy: true,
    useFallback: false,
  },
);
const breakpoint = computed(() => determineImageBreakpoint(props.data));

const hasError = ref(false);
const alternativeUrl = ref<string>();

function handleError(): void {
  alternativeUrl.value = `${config.public.imageUrl}${withoutBase(props.data.attributes.url, "/uploads")}`;
  hasError.value = true;
}
</script>

<style scoped></style>
